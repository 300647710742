









































































































































































































import dateFormat from '@/utils/dateFormat';
import { ExtractVue } from '@/utils/mixins';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import pdf from 'vue-pdf';
import StaffUserForm from '../staffusermanager/StaffUserForm.vue';
import { ErrorManager } from '@/models/error';
import { axios } from '@/plugins/axios';

export default Vue.extend({
  components: { pdf, StaffUserForm },
  name: 'UserAccount',
  data() {
    return {
      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('account', ['account']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },
  methods: {
    ...mapActions({
      fetchAccount: 'account/fetchAccount',
    }),
    dateFormat,

    goToForgetPasswordPage() {
      this.$router.push({ name: 'forget-password' });
    },

    openDialog() {
      const userEditDialog = this.$refs.userform as ExtractVue<
        typeof StaffUserForm
      >;
      userEditDialog.open(this.account, true);
    },
    genUserAccount() {
      this.loading = true;
      this.fetchAccount(this.account.pk)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    },
    downloadImage(url: string) {
      const fileTypeIndex = url.lastIndexOf('.');
      const fileType = url.slice(fileTypeIndex, url.length);

      axios.get(url).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.account.first_name}.${fileType}`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
});
