






import UserAccount from '@/components/accounts/UserAccount.vue'
import Vue from 'vue'
export default Vue.extend({
    name: 'UserAccountsPage',
    components: {
        UserAccount
    },
    metaInfo(){
      return  {
      title: "User Account - Our Nation Express",
     
    };
    }
})
